<template>
  <invoice-preview
    v-if="isLoadEnd"
    :invoice-data="invoiceData"
  />
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import InvoicePreview from '@/views/apps/invoice/invoice-preview/InvoicePreview.vue'
import purchaseRequest from '@/service/purchase/purchase-request'
import { ref } from '@vue/composition-api'

export default {
  components: {
    InvoicePreview,
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      isLoadEnd: false,
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    async getApiSelect() {
      this.isLoadEnd = false
      await purchaseRequest.oneAxios(this.id).then(response => {
        this.invoiceData.master = response.data
        this.invoiceData.items = response.data.purchase_request_details
        this.invoiceData.documentName = this.$t('Purchases Request')
        this.invoiceData.to = this.$t('InvoiceInfo.Request To')
        this.invoiceData.routEdit = 'purchase-updatePurchasesRequest'
        this.invoiceData.permissionEdit = 'PurchasesRequest'
        this.isLoadEnd = true
      }).catch(error => {
        if (error.response.status === 404) {
          this.invoiceData = undefined
        }
      })
    },
  },
  setup() {
    const invoiceData = ref({
      master: {
        id: 0,
        date: '',
        due_date: '',
        description: '',
        based_document_id: '',
        based_document_type_id: '',
        based_document_no: '',
        organization_branch_id: '',
        document_no: '',
        total_price: 0,
        warehouse_id: '',
        financial_year_id: 0,
        status_id: '',
        suspended: false,
      },
      clientOrVenderId: 1,
      document_id: 8,
      document: '',
      clientOrVenderName: 'Supplier',
      client: null,
      items: [],
      file: [],
      balance: 0,
      isEdit: true,
      showError: false,
      showOverLay: false,
      apiErrors: [],
      documentName: 'Request',
      routEdit: 'purchase-updatePurchasesRequest',
      routePerview: 'purchase-previewRequest',
      permissionEdit: 'PurchasesRequest',
      to: 'Request From',
      tableItems: 'purchase_request_details',
      send: true,
      preview: false,
      reset: false,
      save: true,
      edit: false,
      payment: false,
      print: true,
      download: true,
      saveUpdateText: 'Update',
    })
    return {
      invoiceData,
    }
  },
}
</script>
